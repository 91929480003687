.about-wrapper {
  padding-bottom: clamp(2.5rem, 7vw, 3.5rem);
  .contents {
    width: clamp(10rem, 90vw, 80rem);
    margin: clamp(7rem, 22vw, 10rem) auto 0 auto;
    .title-section {
      display: flex;
      flex-direction: column;
      @include section-header;
      .description {
        width: 100%;
        margin-top: 1rem;
        p {
          @include p;
          &:not(&:last-child) {
            margin-bottom: 1rem;
          }
        }
      }
      .sns-wrapper {
        margin-top: 1.5rem;
        display: flex;
        justify-content: space-between;
        width: clamp(8.375rem, 5vw, 9.375rem);
        svg {
          width: 1.25rem;
        }
      }
    }
    .information-section {
      margin-top: clamp(2rem, 10vw, 3.5rem);
      h2 {
        margin: 0;
        color: $white;
        margin-bottom: clamp(1rem, 5vw, 2rem);
        font-family: 'Eina Semi Bold';
        font-size: clamp(1rem, 5vw, 1.5rem);
      }
      p:empty {
        display: none;
      }
      table {
        tr {
          td {
            width: 15rem;
            padding: 0;
            &:last-child {
              width: max-content;
            }
            p {
              margin: 0;
              margin-bottom: 1rem;
            }
            &:first-child {
              font-family: 'Eina Semi Bold';
            }
            @include p;
          }
          &:last-child {
            td {
              &:first-child {
                display: flex;
              }
              &:last-child {
                p {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 62.5rem) {
  .about-wrapper {
    .contents {
      .credits {
        padding-bottom: 0;
        .links {
          flex-direction: column;
          p {
            padding-bottom: 1rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 31.25rem) {
  .about-wrapper {
    .contents {
      .information-section {
        table {
          tr {
            display: flex;
            flex-direction: column;
            td {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}
