%filter-form-buttons {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  button {
    display: flex;
    padding: 0.5rem 1.5rem;
    align-items: flex-start;
    gap: 1.25rem;
    border-radius: 0.5rem;
    box-sizing: border-box;
    font-family: 'Eina Light';
    font-size: 0.75rem;
    &:first-child {
      background-color: $pure-white;
      color: $body-background;
      border: 0.0625rem solid $pure-white;
    }
    &:last-child {
      background-color: transparent;
      color: $white;
      border: 0.0625rem solid $white;
    }
    &:hover {
      cursor: pointer;
    }
    svg {
      width: 0.8125rem !important;
      height: 0.4375rem !important;
    }
  }
}

@mixin filter-button-with-icon($font-size: 1rem) {
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
  gap: 0.625rem;
  border-radius: 1.5625rem;
  border: 0.125rem solid $white;
  color: $white;
  font-family: 'Eina Semi Bold';
  font-size: $font-size;
}

.gallery-wrapper {
  @include section-header;
  width: clamp(18.75rem, 97vw, 91.5625rem);
  margin: clamp(7rem, 20vw, 11.56rem) auto 0 auto;
  padding-bottom: 4rem;
  .title-wrapper {
    width: 100%;
    margin-bottom: 2.5rem;
    .filter-buttons {
      display: flex;
      align-items: center;
      gap: clamp(0.5rem, 5vw, 2.5rem);
      .mobile-filter-button {
        @include filter-button-with-icon(0.75rem);
        &.active {
          color: $body-background;
          background-color: $white;
          svg {
            path {
              fill: $body-background;
            }
          }
        }
      }
      .dropdown {
        position: relative;
        .dropdown-button {
          @include filter-button-with-icon;
          svg {
            width: 1.0625rem;
            height: 0.625rem;
          }
          &.open {
            svg {
              transform: rotateX(180deg);
            }
          }
          &:hover {
            cursor: pointer;
          }
          &.active {
            background-color: $white;
            color: $body-background;
            svg {
              path {
                fill: $body-background;
              }
            }
          }
        }
        .menu {
          position: absolute;
          opacity: 0;
          visibility: hidden;
          display: inline-flex;
          padding: 1rem;
          flex-direction: column;
          align-items: flex-start;
          gap: 1rem;
          margin-top: 0.5rem;
          width: max-content;
          ul {
            padding: 0;
            margin: 0;
            li {
              list-style: none;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 0.625rem;
              color: $white;
              font-family: 'Eina Light';
              font-size: 1rem;
              margin-bottom: 1rem;
              &:last-child {
                margin-bottom: 0;
              }
              &:hover {
                cursor: pointer;
              }
              input,label {
                &:hover {
                  cursor: pointer;
                }
              }
              input[type="checkbox" i] {
                width: 0.875rem;
                height: 0.875rem;
                border-radius: 0.125rem;
                border: 0.0625rem solid $white !important;
                margin: 0;
                accent-color: $white;
              }
            }
          }
          &.open {
            opacity: 1;
            visibility: visible;
            border-radius: 0.5rem;
            border: 1px solid var(--white, #E6E6E6);
            color: $body-background;
            background-color: $body-background;
            z-index: 1;
          }
          .buttons {
            @extend %filter-form-buttons;
          }
        }
      }
    }
    .tags {
      margin-top: 1.5rem;
      display: inline-flex;
      align-items: center;
      gap: clamp(1rem, 3vw, 2rem);
      &, .filter-tags {
        flex-wrap: wrap;
      }
      .filter-tags {
        display: inline-flex;
        align-items: center;
        gap: 1rem;
      }
      .remove-single-filter-button {
        cursor: pointer;
        display: flex;
        padding: 0.5rem 1rem;
        align-items: center;
        gap: 0.625rem;
        border-radius: 1.5625rem;
        border: 0.0625rem solid $white;
        color: $white;
        font-family: 'Eina Regular';
        font-size: clamp(0.75rem, 3vw, 1rem);
        svg {
          width: 0.6875rem;
          height: 0.6875rem;
          pointer-events: none;
        }
      }
      .clear-all-button {
        cursor: pointer;
        color: $white;
        font-family: 'Eina Regular';
        font-size: clamp(0.75rem, 3vw, 1rem);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
      }
    }
  }
  .no-works {
    color: $white;
    font-family: 'Eina Light';
    font-size: clamp(0.75rem, 3vw, 1.25rem);
  }
  .works-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, clamp(10rem, 28vw, 27.1875rem));
    grid-gap: clamp(2rem, 6vw, 4rem) clamp(1rem, 6vw, 5rem);
    @include work-card;
  }
}

.mobile-filter-menu {
  opacity: 0;
  visibility: hidden;
  padding: 1.5rem 2rem;
  background-color: $body-background;
  border-radius: 1.5rem 1.5rem 0rem 0rem;
  border: 0.0625rem solid $white;
  .buttons {
    justify-content: center;
    @extend %filter-form-buttons;
  }
  .clear-all-button-container {
    text-align: center;
    button {
      font-family: 'Eina Light';
      font-size: 0.75rem;
      background-color: transparent;
      color: $white;
      border: 0.0625rem solid $white;
      padding: 0.5rem 1.5rem;
      border-radius: 0.5rem;
    }
  }
  .mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.0625rem solid $white;
    padding-bottom: 0.8125rem;
    button {
      padding: 0;
    }
    .back-button {
      transform: rotateZ(90deg);
      svg {
        width: 0.8125rem;
        height: 0.4375rem;
      }
    }
    .close-button {
      svg {
        width: 0.6875rem;
        height: 0.6875rem;
      }
    }
    p {
      color: $white;
      font-family: 'Eina Semi Bold';
      font-size: 1.125rem;
      margin: 0;
    }
  }
  ul {
    padding: 0;
    li {
      list-style: none;
      width: 100%;
      display: flex;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 0.625rem;
      }
      & input {
        order: 2;
        width: 0.875rem;
        height: 0.875rem;
        border: 0.0625rem solid $white;
        accent-color: $white;
        margin: 0;
      }
      & label {
        order: 1;
        color: $white;
        font-family: 'Eina Regular';
        font-size: 1rem;
      }
      button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        color: $white;
        font-family: 'Eina Regular';
        font-size: 1rem;
        margin-bottom: 0.625rem;
        padding: 0;
        svg {
          width: 0.8125rem;
          height: 0.4375rem;
          transform: rotate(-90deg);
        }
      }
    }
  }
  &.open {
    opacity: 1;
    visibility: visible;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: fit-content;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 35.5rem) {
  .gallery-wrapper {
    overflow-x: hidden;
    .title-wrapper {
      flex-direction: column;
      padding-left: 0.875rem;
      .filter-buttons {
        padding-top: 1rem;
      }
    }
    .works-wrapper {
      justify-content: center;
      grid-template-columns: repeat(auto-fill, clamp(5rem, 42vw, 14rem));
    }
  }
}

@media screen and (max-width: 500px) {
  .gallery-wrapper {
    .tags {
      display: none !important;
    }
  }
}
