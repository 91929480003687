@import './loading.scss';

.top {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  @include loading-screen;
  .credits {
    position: fixed;
    bottom: 2rem;
    left: clamp(1rem, 3vw, 2rem);
    p {
      padding: 0;
      @include p;
    }
    a {
      text-decoration: underline;
    }
  }
}
