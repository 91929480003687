@import '../colors.scss';

%language-switcher {
  .language-switcher {
    display: flex;
    align-items: center;
    .division {
      width: 0.0625rem;
      height: 1rem;
      background-color: $white;
      margin: 0 0.5rem;
    }
    .active {
      font-family: 'Eina Semi Bold';
      pointer-events: none;
    }
  }
}

header {
  z-index: 99;
  position: fixed;
  top: 2.5rem;
  left: 50%;
  transform: translate(-50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;
  transition-delay: 0.1s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  max-width: 95%;
  &.loaded {
    pointer-events: all;
    opacity: 1;
  }
  #logo {
    font-family: 'Stella';
    font-size: clamp(2.7rem, 10vw, 3.75rem);
    &:focus-visible {
      outline: none;
    }
    &:visited {
      color: $white;
    }
  }
  .desktop-navbar {
    ul {
      display: flex;
      align-items: flex-end;
      gap: clamp(1.5rem, 4vw, 3.5rem);
      list-style: none;
      justify-content: space-between;
      padding: 0
    }
  }
  .desktop-navbar, .mobile-navbar {
    ul {
      li {
        @include nav-links;
      }
      @extend %language-switcher;
    }
  }
}
.hamburger-menu {
  width: 100vw;
  height: 100vh;
  background-color: $body-background;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
  z-index: 1;
  display: flex;
  align-items: center;
  transition: opacity 0.2s;
  &:focus-visible {
    outline: none;
  }
  &.open {
    opacity: 1;
    pointer-events: all;
  }
  .contents {
    height: 65%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    a {
      text-decoration: none;
    }
    .links {
      display: flex;
      flex-direction: column;
      margin-left: 40px;
      position: relative;
      top: 150px;
      a {
        color: $white;
        margin-bottom: 20px;
      }
    }
    ul {
      list-style: none;
      li:not(.language-switcher) {
        display: block;
        margin-right: 15px;
        margin-bottom: 10px;
        padding-bottom: 21px;
        a, a:visited {
          color: $white;
        }
      }
      @extend %language-switcher;
    }
    .sns-wrapper {
      display: flex;
      justify-content: space-between;
      // margin: 0 auto;
      padding-left: 40px;
      width: clamp(7rem, 30vw, 12.5rem);
    }
  }
}

@media screen and (min-width: 800px) {
  header {
    .desktop-navbar {
      display: flex;
    }
    .mobile-navbar {
      display: none;
    }
  }
}

@media screen and (max-width: 800px) {
  header {
    .desktop-navbar {
      display: none;
    }
    .mobile-navbar {
      display: flex;
    }
  }
}
