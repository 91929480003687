@import './locomotive-scroll.scss';
@import 'colors';
@import 'fonts';
@import 'texts';
@import './components/_buttons.scss';
@import './components/_cards.scss';
@import './components/header.scss';
@import './components/top.scss';
@import './components/about-us.scss';
@import './components/gallery.scss';
@import './components/work.scss';
@import './components/hamburger.scss';

html, body {
  margin: 0;
  padding: 0;
  height: 100vh;
}

#root {
  height: 100%;
}
body {
  background-color: $body-background;
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
  canvas {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
  }
  @include a;
  a {
    svg {
      color: $white;
    }
  }
  button {
    background-color: transparent;
    border: none;
  }
}
