@font-face {
  font-family: 'Corporate Logo Rounded';
  src: url('../../public/fonts/Corporate-Logo-Rounded.woff');
}

@font-face {
  font-family: 'Eina Light';
  src: url('../../public/fonts/eina-light.woff');
  font-weight: 100;
}

@font-face {
  font-family: 'Eina Regular';
  src: url('../../public/fonts/eina-regular.woff');
  font-weight: 100;
}

@font-face {
  font-family: 'Eina Semi Bold';
  src: url('../../public/fonts/eina-semi-bold.woff');
}

@font-face {
  font-family: 'Eina Bold';
  src: url('../../public/fonts/eina-bold.woff');
}

@font-face {
  font-family: 'Stella';
  src: url('../../public/fonts/stella.ttf');
}

// TODO: Delete

@font-face {
  font-family: 'Branch';
  src: url('../../public/fonts/branch.ttf');
}
