$transition-duration: 0.3s;

@mixin cta-button {
  .cta-button {
    display: flex;
    height: 100%;
    padding: 0.5rem 1.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    border: 0.0625rem solid $white;
    transition-duration: $transition-duration;
    &:hover {
      background-color: $white;
      p {
        color: $body-background !important;
      }
    }
  }
}

@mixin common-arrow-button {
  border-radius: 50%;
  padding: 1rem;
  border: 0.125rem solid $white;
  width: clamp(1rem, 2vw, 1.5rem);
  height: clamp(1rem, 2vw, 1.5rem);
  transition-duration: $transition-duration;
  path {
    stroke: $white;
  }
  &:hover {
    background-color: $white;
    cursor: pointer;
    path {
      stroke: $body-background;
    }
  }
  &:focus-visible {
    outline: none;
  }
}

@mixin previous-navigation-button {
  .previous-button {
    transform: scaleX(-1);
    @include common-arrow-button;
  }
}

@mixin next-navigation-button {
  .next-button {
    @include common-arrow-button;
  }
}
